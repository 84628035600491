
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    data(): any {
        return {
            item: undefined as any,
        };
    },
    mounted(): void {
        this.getItem(this.$route.params.id);
    },
    methods: {
        getItem(id: string): void {
            this.get(`platforms/${id}`).then(({ data }: any) => {
                this.item = data.data;
                (this.$refs.form as any).setValues(this.item);
            });
        },
        saveItem(): void {
            this.put(`platforms/${this.$route.params.id}`, {
                ...this.item,
                instructions: this.item.translations.instructions,
            }).then(() => {
                this.$router.push({ name: 'platforms.list' });
                this.$emit('reload');
            });
        },
    },
});
